.chip {
  display: inline-flex;
  padding: 0 10px;
  height: fit-content;
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  background-color: #4aa2b4;
}

.chipclosebtn {
  padding-left: 10px;
  color: white;
  font-weight: bold;
  align-self: center;
  font-size: 20px;
  cursor: pointer;
}

.chipclosebtn:hover {
  color: #000;
}
