
.editor_class{
    background-color: transparent !important;
    height: 40px !important;
    border-radius: 4px !important; 
    padding: 0 0 0 2px !important;
    font-size: 14px !important;
    border: 1px solid #74b44a;
    width: 100%;
  }

.editor_class:focus{
    font-weight: 500 !important;
}


