#dropdown_menu > .dropdown-item:focus,
#dropdown_menu > .dropdown-item:hover {
  background: #cfe7ec !important;
}

#dropdown_menu {
  min-width: unset;
}

#profile_ations_dropdown::after {
  all: unset !important;
}

.user-name {
  color: #2f481e;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  white-space: break-spaces;
  word-break: break-word;
}

#profile_ations_dropdown,
.dropdown_btn {
  color: #2f481e;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

#profile_ations_dropdown {
  text-align: center;
  background: transparent;
  border: none;
  white-space: break-spaces;
  word-break: break-word;
}

#profile_ations_dropdown:hover,
#profile_ations_dropdown:active,
#profile_ations_dropdown:focus {
  background: #dae0e5;
}

.dropdown_btn {
  border-radius: 8px;
  height: 42px;
  padding-left: 1rem;
}

.dropdown_link {
  color: #2f481e;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  border-radius: 8px;
  height: 42px;
  padding-top: 10px;
  padding-left: 1rem;
}

.different-placeholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84857a !important;
  opacity: 1; /* Firefox */
}

.different-placeholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #84857a !important;
}

.different-placeholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #84857a !important;
}

#view-unit {
  color: #2f481e;
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 55px;
  border-radius: 8px;
  padding: 10px 15px;
  border: 1px solid #74b44a;
}

.dark-green{
  color: #2f481e !important;
}