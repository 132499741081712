.row {
  display: flex;
  flex-direction: row;
}

.report-actions{
  padding: 20px;
  justify-content: space-between;
}

.reportDefault p {
  color: #53a6b7;
  font-size: 15px;
  font-weight: 700;
}
.align-text {
  padding: 10px 5px !important;
}

.mainheading {
  font-style: italic;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: large;
  padding: 4px 10px;
}

.ulstylecomment {
  list-style-type: none;
  padding: 0px 10px;
}

.no-border {
  border: none !important;
}

.dialogtextarea {
  margin: 25px 0px 0px 0px;
}

.dialogtextarea textarea {
  resize: none !important;
  height: 40vh !important;
}

.modal-content {
  border-radius: 10px !important;
}

.textDivEditable span:focus {
  outline: none;
}

.textDivEditable {
  width: 399px;
  overflow-y: auto;
  text-align: start;
  padding: 31px 17px 10px 20px;
  outline: 0;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 10px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  white-space: pre-line;
}

@media only screen and (max-width: 1263px) {
  /* Styles */
  .textDivEditable {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1440px) {
  /* Styles */
  .textDivEditable {
    width: 85% !important;
  }
}

@media only screen and (max-device-height: 667px) {
  /* Styles */
  .align-text {
    margin-left: 7%;
  }
}
