.row {
  display: flex;
  flex-direction: row;
}

.mainheading {
  font-style: italic;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: large;
  padding: 4px 10px;
}

.room-accordian-style {
  margin: 18px 0px;
  padding: 5px 10px;
  color: #2f481e;
  border-radius: 10px;
  background: #e7ece8;
  font-weight: bold;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.no-border {
  border: none !important;
}

.dialogtextarea {
  margin: 25px 0px 0px 0px;
}

.dialogtextarea textarea {
  resize: none !important;
  height: 40vh !important;
}

.modal-content {
  border-radius: 10px !important;
}

.bold-hr {
  border: 1px solid #2f481e;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #2f481e;
}

.protocol-top-text {
  font-size: 18px;
  font-weight: bold;
}

.protocol-details-section {
  padding-bottom: 10px;
}

.my-editing-area1 {
  border: 1px solid black;
}

.my-editing-area2 {
  border: 1px solid red;
}

.protocol-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.protocol-list > svg {
  display: none;
}

.protocol-list:hover > svg {
  display: block;
}
