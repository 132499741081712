.container {
    padding: 120px 30px 50px 260px;
    margin-bottom: 30px;
    background-color: #e6ece8;
    height: calc(100vh - 0px);
    overflow: auto;
}

.reportTitle{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.report_container{
    background-color: white;
    padding: 20px !important;
    border-radius: 8px;
    box-shadow: -1px 4px 8px 4px #b9b9b966;
    position: relative;
    height: fit-content;
    transition: height 1s;

}

.report_container.loading .report_container_content {
  opacity: 0.5; /* Dim the content */
  pointer-events: none; /* Disable interaction */
}

.table_container{
    /* max-height: calc(100vh - 140px); */
    overflow: auto;
    width: 100%; 
    overflow-x: auto; 
    position: relative;
  
}

.table_container :global .react-bootstrap-table table{
    border: none !important;  
    border-collapse: collapse;
    margin-bottom: 0 !important;
    width: -webkit-fill-available; 

}


.table_container :global .react-bootstrap-table table tr{
    height: 40px !important;
}


.table_container :global .react-bootstrap-table table th{
    border-right: 1px solid #c5c5c5 !important; 
    border-bottom: 1px solid #c5c5c5 !important; 
    vertical-align: middle;
    background-color: #e4fad3; 
    font-weight: bold;
    font-style: normal;
    color: black;
    font-size: 14px !important;
}

.table_container :global .react-bootstrap-table table thead:first-child {
  padding: 0 !important;
 }

.table_container :global .react-bootstrap-table table .alert{
    margin-top: 8px !important;
}

.table_container :global .react-bootstrap-table table th:last-child , .table_container :global .react-bootstrap-table table td:last-child{
    border-right: none !important; 
}

.table_container :global .react-bootstrap-table table td{
    background-color: transparent !important;
    border-right: 1px solid #c5c5c5 !important; 
    border-bottom: 1px solid #c5c5c5 !important; 
    font-size: 14px !important;
    
}

.table_container :global .react-bootstrap-table table td:first-child{
   border-left: none !important;
   padding: 0px !important;
   margin: 0 auto !important; 
   text-align: center !important;  
   vertical-align: middle !important;
}

.table_container :global .react-bootstrap-table table tr:last-of-type td{
    border-bottom: none !important; 
}

.table_container :global .react-bootstrap-table table .selection-cell input[type="checkbox"] {
    position: relative;
    appearance: none; 
    width: 16px;
    height: 16px;
    background-color: white; 
    border: 1px solid #74b44a; 
    border-radius: 3px;
    cursor: pointer;
    outline: none;
  }
  
  .table_container :global .react-bootstrap-table table .selection-cell input[type="checkbox"]:checked {
    background-color: #74b44a;
    border-color: #74b44a; 
  }
  
  .table_container :global .react-bootstrap-table table .selection-cell input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .select_checkbox {
    position: relative;
    appearance: none; 
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-color: white; 
    border: 1px solid #74b44a; 
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    margin-top: 2px;
  }
  
  .select_checkbox:checked {
    background-color: #74b44a;
    border-color: #74b44a;   }
  
  .select_checkbox:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .hide_btn_container{
    display: flex;
    justify-content: space-between;
  }

  .icon {
   height: 20px;
    cursor: pointer;
  }



  /* HTML: <div class="loader"></div> */

  .loader_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* Slightly opaque white background */
    z-index: 999; /* Ensure it's above other content */
  }
  
  .loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
  }
  
  .loader {
    --d: 22px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    color: #74b44a;
    box-shadow:
      calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
      calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
      calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
      calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
      calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
      calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
      calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
    animation: l27 1s infinite steps(8);
  }
  
  @keyframes l27 {
    100% { transform: rotate(1turn) }
  }

.reportFooter{
  display: flex;
  align-items: end;
  justify-content: space-between;
  overflow: hidden;
  /* margin-bottom: 25px; */
  
}
.summary{
   display: flex;
   justify-content: end;
  align-items: end;  
}
.addon{
  width: 100%;
}


.checkInputCnt {
  width: 100%;
  display: flex;
  justify-content: center;
  
}

.checkInputCnt:hover {
  cursor: pointer;
}

.selectAllCheck {
  width: 100%;
  min-width: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anchor_refresh_btn {
  padding: 0 !important;
  border: 0;
  border-radius: 20px;
  border-radius: 20px !important;
  min-width: 0px !important;
  width: fit-content !important;
  background-color: transparent !important;
}

.anchor_refresh_btn img {
  width: 35px;
}

.anchor_refresh_btn img:hover {
  opacity: 0.6;
}


.hideIcon {
  height: 15px;
  cursor: pointer;
}

.unhideIcon {
  height: 20px;
  cursor: pointer;
}


