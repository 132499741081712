.cover-page-heading {
  text-align: center;
  margin-top: 0.25cm;
}

.comp-logo {
  margin-top: 2.5cm;
}

.cover-img {
  width: 14cm;
  height: 7cm;
  margin: 0 auto;
  object-fit: contain;
}

.cover-client-info {
  text-align: center;
  padding: 0.5cm 0 0 0cm;
}

.document-head {
  padding: 0.5cm 0cm;
  border-bottom: 0.5px solid gray;
}

.fw-500 {
  font-weight: 500;
}

.footer-child {
  margin-top: 15px;
}

.fw-bold {
  font-weight: bold;
}

.grid-container {
  display: flex;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #84857a;
  text-align: center;
  width: 100%;
}

.logo-footer {
  text-align: center;
  width: 3cm;
  height: 3cm;
  object-fit: contain;
}

.grid-pg1 {
  padding: 0 0.5cm;
  display: grid;
  grid-template-columns: 60% 40%;
  margin-bottom: -20px;
}

.grid-pg1-item2 {
  text-align: end;
}

.divided {
  background: linear-gradient(transparent 45%,
      black 45%,
      black 55%,
      transparent 55%);
}

.divided span {
  background: white;
  padding: 0 5px;
}

.divided span:last-of-type {
  float: right;
}

.table-of-contents {
  padding: 0px 20px;
}

.toc-sec {
  margin: 35px 0px 0px 0px;
  font-size: small;
}

.page-content {
  padding: 20px 25px;
}

.pg-num {
  position: absolute;
  right: 0.75cm;
  bottom: 0.5cm;
}

.observation-info {
  height: 1.7cm;
  display: grid;
  grid-template-columns: auto auto auto auto;
  background-color: #e7ece8;
  padding: 0 1cm;
  align-items: center;
}

.observation-info * {
  margin: 0;
}

.img-container-1 {
  padding-left: 12px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto auto;
  grid-row-gap: 20px;
  grid-column-gap: 5px;
}

/* table {
    margin-top: 30px;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
  th,
  td {
    padding: 10px;
  }
  .table-grid-1 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  #pg-4-list li {
    margin: 15px 10px 0px 0px;
  } */

.image-editor-tools {
  display: none;
  font-size: 16px;
  position: absolute;
  height: 100%;
  align-items: center;
  right: -20px;
}

.image-editor {
  display: inline-flex;
  position: relative;
}

.report-img:hover .image-editor-tools,
.report-img:hover .rotate-btns {
  display: flex;
}

.image-editor-tools svg {
  cursor: pointer;
}

.image-editor {
  border: 2px solid transparent;
}

.report-img:hover .image-editor {
  border-color: #4aa2b4;
}

.rotate-btns {
  z-index: 1;
  width: 80px;
  height: 40px;
  background: rgb(222, 226, 223);
  border-radius: 8px;
  position: absolute;
  left: 26px;
  top: 6px;
  color: #84857a;
  display: none;
  justify-content: space-around;
  align-items: center;
  font-size: 22px;
}

.rotate-btns svg {
  cursor: pointer;
}

.vl {
  border-left: 1px solid #84857a;
  height: 25px;
}

.comments-heading {
  align-items: center;
  display: flex;
  padding: 0.5cm;
}

.comments-heading * {
  margin: 0;
}

.comments-heading>span {
  border-top: 1px solid black;
  width: 100%;
  height: 0px;
  margin-left: 14pt;
}

.comment-remarks * {
  font-size: 12pt;
  word-break: break-word;
}

.test-photo-img {
  width: 3.5cm;
  height: 4cm;
  object-fit: contain;
}

.obs-photo-img {
  width: 8cm;
  height: 5cm;
  object-fit: contain;
}

.obs-photo-caption {
  font-size: 10pt;
  max-width: 9cm;
  text-align: center;
}

.upload-new-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5cm;
  height: 4cm;
}

.test-table {
  width: -webkit-fill-available;
  border-spacing: 0px;
  margin: 0 0.5cm;
}

.test-table>tbody>tr:first-child {
  background-color: #84857a;
  color: white;
  font-weight: bold;
  height: auto;
}

.test-table>tbody>tr td:last-child {
  border: none;
}

.test-table>tbody>tr:last-child {
  background-color: #e7ece8;
  color: #2f481e;
  font-weight: bold;
  height: auto;
}

.test-table>tbody>tr:first-child td {
  padding: 10px 0;
}

.test-table>tbody>tr:last-child td {
  border-bottom: 2px solid #b9b9b9;
  padding: 14px 0;
}

.test-table tr td {
  border-radius: 0px;
  text-align: center;
  hyphens: auto;
  border-right: 2px solid #b9b9b9;
}

.photos-grid {
  margin: 0 0.5cm;
  background-color: #e7ece8;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.test-list {
  margin-bottom: 0.4cm;
}

.report-protocol-list>li {
  padding-bottom: 0.75rem;
}

.report-protocol-list>li::marker {
  font-weight: bold;
}

.sections-list {
  list-style-type: none;
  padding: 0;
  margin: 0 1cm;
}

.section-list-title {
  font-weight: bold;
  padding-bottom: 5pt;
  padding-top: 15pt;
}

.sub-sections-list {
  list-style-type: none;
  padding-left: 0;
}

.sub-section-list-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.sub-section-list-title>div:first-child {
  width: max-content;
  white-space: nowrap;
}

.sub-section-list-title>div:last-child {
  width: -webkit-fill-available;
  border-top: 1px solid #84857a;
  height: 0px;
  margin-left: 12pt;
}

.template-body p {
  margin-bottom: 0 !important;
}

.template-body * {
  hyphens: auto;
  word-break: break-word;
}

.no-margin * {
  margin-bottom: 0 !important;
}

.break-text {
  hyphens: auto;
  word-break: break-word;
}

.textDesign {
  max-width: 8cm;
}