.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: #3c763d;
  font-size: 24px;
}

.tablesContainer {
  display: flex;
  gap: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  /* max-width: 300px; */
}

.header {
  vertical-align: middle;
  background-color: #e4fad3;
  font-weight: bold;
  font-style: normal;
  padding: 8px 15px;
  border: 1px solid #ccc;
  text-align: center;
}
.headerExpedites {
  vertical-align: middle;
  background-color: #d5f3fe;
  font-weight: bold;
  font-style: normal;
  padding: 8px;
  border: 1px solid #ccc;
  text-align: center;
}

td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: start;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

tbody tr {
  height: 0px !important;
}

.totalRow {
  background-color: #e6f5d5 !important;
  font-weight: bold;
}
.totalCount {
  color: #74b44a;
  text-align: center;
}

.doneButton {
  background-color: #74b44a;
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.doneButton:hover {
  background-color: #74b44ac4;
}

.table tr:nth-child(odd) {
  background-color: white;
}

.table tr:nth-child(even) {
  background-color: #fafafa;
}

.selectCnt {
  padding: 0px !important;
}

.selectCnt:hover {
  cursor: pointer;
}

.select_checkbox {
  position: relative;
  appearance: none;
  width: 21px;
  height: 21px;
  background-color: white;
  border: 2px solid #74b44a;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  margin-top: 8px;
}

.select_checkbox:checked {
  background-color: #74b44a;
  border-color: #74b44a;
}

.select_checkbox:checked::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
