.circleProgress {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
}

.circleLine {
  width: 8px;
  height: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9px;
}
.lineSizeBig {
  width: 14px;
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.newColor {
  background-color: #74b44a;
}

.prevColor {
  background-color: #84857a;
}

.bigSize {
  height: 32px;
  width: 32px;
}
.normalSize {
  width: 20px;
  height: 20px;
}
