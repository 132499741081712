.container{
    position: fixed;
    top: 100px;
    z-index: 999;
    right: 50px;
}

.action_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    right: 2rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.save_btn, .refresh_btn, .anchor_btn {
    height: 40px !important;
    width: 100px !important;
    color: #fff !important;
    background-color: #4aa2b4 !important;
    border-color: #4aa2b4 !important;
}

.publish_btn {
    height: 40px !important;
    width: 100px !important;
    color: #fff !important;
}

.custom-dropdown-item:hover {
    background-color: green !important;
}