.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  width: 100%;
  margin: 0;
  padding-left: 260px;
  padding-right: 30px;
  background: #fff;
  z-index: 2;
}

.header .heading-icon {
  width: 20px;
  margin-right: 15px;
}
.header h3 {
  font-size: 22px;
  height: 29px;
}

.open-menu-icon {
  margin-right: 10px;
  font-size: 30px;
  cursor: pointer;
}

.header-title {
  font-weight: bold;
  margin: unset;
}

.new-rect {
  padding-left: 42px;
  height: 40px;
  width: 270px;
  border-radius: 8px;
  outline: none;
  background-color: #e7ece8;
  border: none;
  padding-right: 37px;
  /* transition-property: width; */
  /* transition-duration: 0.14s; */
  /* transition-delay: 0.13s; */
  transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
}
.header .search-box-icon {
  position: absolute;
  cursor: pointer;
  margin: 8px 16px 8px 12px;
}
.header .cancel-box-icon {
  position: absolute;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin: 12px 0px 8px 239px;
}

.header .search-header-activate {
  font-weight: 600;
  text-align: right;
  margin-top: 14px;
  padding: 0px 12px;
  display: initial;
  /* transition: all ease-in-out 2s; */
}
.margin-without {
  margin: 0px !important;
}
.header .active-color {
  color: #2f481e;
  font-size: 13px;
}
.header .non-active-color {
  color: #84857a;
  font-size: 16px;
}
@media screen and (max-width: 991px) {
  .header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .open-menu-icon {
    display: inline-block;
  }
}

@media screen and (min-width: 992px) {
  .open-menu-icon {
    display: none;
  }
}

.header .go-back {
  display: flex;
  flex-direction: row;
  color: #4aa2b4;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  width: fit-content;
}

.go-back .go-back-text {
  font-weight: 600;
  font-size: 15px;
  margin-left: 5px;
}

.radio-btn {
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.radio-label {
  font-weight: 600;
  font-size: 14px;
  color: #2f481e;
  cursor: pointer;
}
