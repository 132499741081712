/* summary.module.css */

.summaryDetailContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #f8f9fa; /* light grey */
  border-radius: 1px;
  width: 480px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform .5s;
  opacity: 0;
  transition: transform 0.5s, opacity 0.8s;
}

.summary_container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d5f3fe;
  padding: 10px;
  border-radius: 1px;
  font-weight: bold;
  width: 480px;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

.summaryHideDetailContainer {
  border: 0;
  opacity: 0;
  transform: translateX(350px);
}

.summaryShowDetailContainer {
  opacity: 1;
  transform: translateX(0px);
  margin-bottom: 20px;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d5f3fe38;
  padding: 10px;
  border-radius: 1px;
  font-weight: bold;
  width: 480px;
  z-index: 0;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: transform .5s;
}

.summary:focus-visible, .summary:focus, .header:focus-visible, .header:focus, .subHeader:focus-visible, .subHeader:focus {
  outline: 0 !important;
}

.showSubHeader {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(0);
}

.hideSubHeader {
  box-shadow: 0;
  border: 0;
  transform: translateY(-40px);
}

.header .balance {
  color: black; /* green */
  margin-right: 16px;
}

.sampleIcon {
  width: 24px;
  height: 24px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.list-item:last-child {
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.total {
  font-weight: bold;
  color: white;
  background-color: #57932f; /* green */
  border: 1px solid #57932f;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Summary {
  float: right;
  margin-top: 20px;
  min-height: 100px;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}
.Inspection {
  border: rgb(88, 84, 84) 1px solid;
  padding: 7px;
  border-radius: 5px;
  font-weight: bold;
  min-width: 100px;
}
.listGroup {
  height: 0px;
}
.price {
  font-weight: bold;
  margin-right: 8px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.editorInput {
  border: lightgray 1px solid;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  max-width: 100px;
  text-align: end;
  margin-left: auto;
}
