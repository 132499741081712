.zoomable-image {
  max-height: 100vh;
  object-fit: contain;
  width: 100% !important;
  pointer-events: auto !important;
}

.body-container {
  background: #0e0e0e;
  display: flex;
  width: 100% !important;

  /* justify-content: center; */
  align-items: center;
  height: 100vh;
  overflow: auto;
}
.body-container .react-transform-wrapper {
  width: 100% !important;
}

.body-container .react-transform-wrapper .react-transform-component {
  width: 100% !important;
}
