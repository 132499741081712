
  .editor-class{
    background-color: transparent !important;
    height: 40px !important;
    border-radius: 4px !important; 
    border: none;
    padding: 0 0 0 2px !important;
    font-size: 14px !important;
  }

.selected-row {
  background-color: #f5f5f5;
  border-radius: 0 !important;           /* Ensure no curves */
  box-shadow: none !important;           /* Remove any shadow */
  border: none !important;               /* Remove any border */
}
  

.client_approved {
  background-color: #fff4e7;
  border-radius: 0 !important;           /* Ensure no curves */
  box-shadow: none !important;           /* Remove any shadow */
  border: none !important;               /* Remove any border */
}

.discount-row {
  border-radius: 0 !important;           /* Ensure no curves */
  box-shadow: none !important;           /* Remove any shadow */
}
 
.discount-row .strike-throught {
  text-decoration: line-through;
}