.container {
  display: flex;
  align-items: center;
  float: right;
  gap: 12px;
  
}
.btnExpedite {
  width: 105px;
  height: 30px;
  background-color: #fff !important;
  color: #4aa2b4 !important;
  border-color: #4aa2b4 !important;
  font-weight: 400 !important;
  border-radius: 8px;
}
.btnFinancial {
  height: 30px !important;
  width: 105px !important;
  background-color: #fff !important;
  color: #74b44a !important;
  border-color: #74b44a !important;
  font-weight: 400 !important;
}

.summary_footer {
  display: flex;
  align-items: center;
  justify-content:end;
}

.anchor_btn {
  height: 35px !important;
  width: 100px !important;
  color: #fff !important;
  background-color: #4aa2b4 !important;
  border-color: #4aa2b4 !important;
}

.discountBtnCnt {
  display: flex;
}

.anchor_btn {
  height: 35px !important;
  width: 100px !important;
  color: #fff !important;
  background-color: #4aa2b4 !important;
  border-color: #4aa2b4 !important;
}

.anchor_discount_btn, .anchor_expedite_btn, .anchor_financial_btn {
  padding: 0 !important;
  border: 0;
  border-radius: 20px;
  border-radius: 20px !important;
  min-width: 0px !important;
  width: fit-content !important;
  background-color: transparent !important;
}


.anchor_discount_btn img, .anchor_expedite_btn img, .anchor_financial_btn img {
  width: 35px;
}
.anchor_discount_btn img:hover, .anchor_expedite_btn img:hover, .anchor_financial_btn img:hover {
  opacity: 0.6;
}

