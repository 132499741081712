#report {
  width: 100%;
  min-height: calc(100vh - 90px);
  margin-top: 90px;
  padding-left: 230px;
}

@media screen and (max-width: 992px) {
  #report {
    padding-left: 0;
  }
}

#report>.ls {
  width: 25%;
  background: white;
}

#report>.rs {
  width: 75%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #e7ece8;
  padding-left: 8px;
  overflow: hidden;
  height: calc(100vh - 90px);
  position: relative;
}

.report-action-container {
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  display: flex;
  width: min-content;
  position: absolute;
  z-index: 1;
  right: 2rem;
  top: 1rem;
}

.report-action-container>.report-save-btn {
  background-color: #4aa2b4 !important;
  border-color: #4aa2b4 !important;
  margin-right: 1rem;
}

.report-action-container>.report-save-btn:hover,
.report-action-container>.report-save-btn:active {
  background-color: #3e8999 !important;
  border-color: #3e8999 !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: unset !important;
  overflow-x: initial !important;
}

.section-accordion {
  margin: 18px 0px;
  padding: 5px 10px;
  color: #2f481e;
  border-radius: 10px;
  background: #e7ece8;
  font-weight: bold;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.section-children {
  cursor: pointer !important;
  color: #84857a;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
}

.ul-ir {
  list-style-type: none;
  padding: 0px 10px;
  height: calc(100vh - 106px);
  margin: 5px 0px 0px 0px !important;
  word-break: break-word;
  overflow-y: auto;
}

.A4 {
  background: white;
  width: 21cm;
  height: 29.7cm;
  border: 1px solid transparent;
  border-left: none;
  border-right: none;
  transform-origin: 0 0;
  font-family: "Exo 2";
  color: #2f481e;
  margin: 10px auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  .A4,
  .A4 * {
    visibility: visible;
  }

  .A4 {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    box-shadow: none;
    background: initial;
    page-break-after: always;
    border: 1px solid black;
    -webkit-print-color-adjust: exact;
  }
}

.A4 tr {
  height: auto;
}

.A4 tr td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.A4 tr td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tools {
  display: none;
  font-size: 16px;
  position: absolute;
  height: 100%;
  align-items: center;
}

.pen {
  left: 0px;
}

.cross {
  right: 0px;
}

.tools svg {
  cursor: pointer;
}

.editable-text:hover .tools {
  display: flex;
}

.editable-text {
  display: inline-flex;
  position: relative;
  padding-left: 22px;
  padding-right: 22px;
  cursor: pointer;
}

.editable-text span {
  border: 2px solid transparent;
}

.editable-text:hover>span:first-of-type {
  border-color: #4aa2b4;
}

.max-length-count {
  display: flex;
  justify-content: flex-end;
}

.doc-body {
  margin: 0.5cm;
  overflow: auto;
  height: calc(100% - 180px);
}

.sub-grid {
  display: grid;
  grid-template-columns: max-content auto;
}

.sub-grid p:first-child {
  padding-left: 6pt;
}

.sub-grid p:last-child {
  margin-top: -2px;
  margin-left: -15px;
}

.obs-photo-rotate-btns {
  z-index: 1;
  width: 55px;
  height: 30px;
  background: rgb(222, 226, 223);
  border-radius: 8px;
  position: absolute;
  left: 2px;
  top: 6px;
  color: #84857a;
  display: none;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
}

.obs-photo-rotate-btns svg {
  cursor: pointer;
}

.report-img:hover .image-editor-tools,
.report-img:hover .obs-photo-rotate-btns {
  display: flex;
}

.section-children-active {
  color: #2f481e;
}

.img-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 4;
}

.editable-text p {
  margin-bottom: unset !important;
  line-height: 23px !important;
  color: unset !important;
  background-color: transparent !important;

}

.editable-text table {
  width: 640px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;

}


.ReactVirtualized__List {
  outline: none;
}