.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: #3c763d;
  font-size: 24px;
}

.tablesContainer {
  display: flex;
  gap: 20px;
}

.financingDataTable {
  border-collapse: collapse;
  width: 100%;
  /* max-width: 300px; */
  box-shadow: #ccc 0px 0px 5px;
}

.header {
  vertical-align: middle;
  background-color: #e4fad3;
  font-weight: bold;
  font-style: normal;
  padding: 8px;
  border: 1px solid #ccc;
  text-align: center;
}
.headerExpedites {
  vertical-align: middle;
  background-color: #d5f3fe;
  font-weight: bold;
  font-style: normal;
  padding: 8px;
  border: 1px solid #ccc;
  text-align: center;
}

td {
  /* padding: 12px; */
  border: 1px solid #ccc;
  text-align: start;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

tbody tr {
  height: 0px !important;
}

.totalRow {
  background-color: #d5f3fe !important;
  font-weight: bold;
}
.totalCount {
  /* color: #74b44a; */
  text-align: center;
}

.doneButton {
  background-color: #8bc34a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.doneButton:hover {
  background-color: #7cb342;
}

.financingDataTable tr:nth-child(odd) {
  background-color: white;
}

.financingDataTable tr:nth-child(even) {
  background-color: #fafafa;
}

.financingDataTable td {
  padding: 10px 20px;
}

.tableDiscount {
  border-collapse: collapse;
  width: 100%;
  /* max-width: 300px; */
}
.tableDiscount td {
  padding: 5px 20px;
  border: 1px solid #ccc;
  text-align: start;
  font-weight: bold;
}
.tableDiscount tr {
  height: 0px !important;
}
.tableDiscount tr:nth-child(odd) {
  background-color: blanchedalmond;
}

.tableDiscount tr:nth-child(even) {
  background-color: blanchedalmond;
}
.sampleTable {
  border-collapse: collapse;
  width: 100%;
  /* max-width: 300px; */
  box-shadow: #ccc 0px 0px 5px;
}
.sampleTable th {
  padding: 5px;
  border: 1px solid #ccc;
  text-align: start;
  font-weight: bold;
}
.sampleTable td {
  padding: 5px 20px;
  border: 1px solid #ccc;
  text-align: start;
  font-weight: 400;
}
.sampleTable tr {
  height: 0px !important;
}
.sampleTable tr:nth-child(odd) {
  background-color: #fafafa;
}
.financingTable {
  border-collapse: collapse;
  width: 100%;
  /* max-width: 300px; */
  box-shadow: #ccc 0px 0px 5px;
}
.financingTable td {
  padding: 5px 20px;
  border: 1px solid #ccc;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}
.financingTable th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
}
.financingTable td {
  padding: 8px 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  text-align: start;
  font-weight: 400;
}
.financingTable tr {
  height: 0px !important;
}
.financingTable tr:nth-child(odd) {
  background-color: #fafafa;
}

.customInputStyles {
  width: 100px;
  background-color: transparent !important;
  border-radius: 5px;
  border: 1px solid black;
  text-align: center;
  color: black;
}

.customInputStyles::placeholder {
  color: black !important;
}

.discountErrorCnt {
  margin-top: 5px;
  display: flex;
  justify-content: start;
  align-items: start;
}

.errordicountTxt {
  text-align: start;
  font-size: 12px;
  color: #b44a4a;
  font-weight: 400;
  margin-bottom: 0;
}

.discountErrorImg {
  margin-top: 3px;
  width: 13px;
  margin-right: 5px;
}