.cardstyle {
    box-shadow: 1px 1px 5px 1px #b9b9b936;
}

.centerdiv {
    width: 40%;
    margin: auto;
}

.cardinnerstyle {
    padding:5%;
    text-align: center;
}

.couponStyle{
    padding: 0px 30px;
}

.couponStyle h4 {
    text-align: initial;
}

.descstyle{
    padding: 10px;
    font-size: 13px;
}

.descstyle > p{
    text-align: center;
}