.container{
    margin:10px 0

}
.information_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;
    width: fit-content;
}

.info_key{
    font-weight: bold;
    min-width: 120px;
}

.client_info{
    font-weight: bold;
}

.lead_info{
    width: 480px;
}

.inspector_info{
    width: 480px;
}

.company_logo{
    margin-top: 20px;
    margin-right: 40px;
    max-width: 180px;
    max-height: 180px;
    object-fit: cover;
}