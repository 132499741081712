.head-tab-btns {
  background: #fff;
  padding: 0 30px;
}
.head-tab-btns .btn {
  color: #84857a;
  font-size: 15px;
  font-weight: 500;
  border-radius: 2px;
  padding: 10px 15px;
  border: 0;
  border-bottom: 3px solid transparent;
  min-width: 192px;
}
.head-tab-btns .btn.active {
  background: #e8f9d2;
  font-weight: 700;
  border-color: #74b44a;
  color: #2f481e;
}

.statsBlock {
  padding: 17px 18px;
  width: 100%;
  height: 120px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 4px;
  text-align: initial;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}

.statsBlock span {
  font-weight: bold;
  font-size: 18px;
  color: #2f481e;
}

.BU_stats_num {
  font-size: 24px !important;
}

.admin-edit .ql-editor {
  height: calc(100vh - 500px - 5vh) !important;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
