.radioIconImage{
    padding-right: 15px;
}
.multiValueContainer {
    display: flex;
    overflow-x: hidden;
}
.check_icon{
    color: #74b44a;
}
  
