.filter-container-visible {
  position: absolute;
  z-index: 1;
  opacity: 1;
  transition: 0.5s;
}

.filter-container-hidden {
  position: absolute;
  z-index: 0;
  transition: 0.5s;
  opacity: 0;
}

.close-filter {
  position: absolute;
  right: -6px;
  top: -6px;
  color: #84857a;
  cursor: pointer;
}

.clear-filter {
  margin-left: 0.5rem;
  color: #84857a;
  cursor: pointer;
}
