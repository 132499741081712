.listStyle {
  border: white !important;
  border-radius: 5px;
}

/* .list-group {
  height: 500px;
} */

.modalBodyStyles {
  padding: 0% 7% !important;
}

.listItems label {
  margin-bottom: auto;
}
.custom-checkbox input {
  width: 24px;
  height: 22px;
}

.inspection-checkbox-all {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 24px; 
  height: 22px;
  margin-left: 5px;
  border-radius: 3px;
  border: 1px solid rgb(128 128 128 / 62%);
  background-color: white;
  cursor: pointer;
  position: relative;
}

.inspection-checkbox-all:checked {
  background-color: #4aa2b4 !important;
  border-color: #007bff;
}

.inspection-checkbox-all:checked::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  top: 4px;
  left: 8px;
  transform: rotate(45deg);
}


.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  width: 24px;
  height: 22px;
  background-color: #4aa2b4 !important;
}


.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  width: 24px;
  height: 22px;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}


.custom-checkbox .custom-control-label::before {
  width: 24px;
  height: 22px;
  background-color: #ffffff;
}

.custom-checkbox .custom-control-label::after {
  width: 24px;
  height: 22px;
}

.custom-control-label span {
  margin-top: 4px;
  margin-left: 16px;
  /* display: flex; */
}

.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  margin: 0px 15px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-contain {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  margin: 0px 15px;
  border-radius: 50%;
  object-fit: cover;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #4aa2b4;
  padding: 2px;
}

.text-trunk-prop {
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px !important;
  text-overflow: ellipsis;
}

.span-style-none {
  margin-top: 0px !important;
  margin-left: 0px !important;
  /* display: block !important; */
}
.ps__rail-x,
.ps__rail-y {
  opacity: 0.6;
}

.w-500px{
  width: 500px;
}


.first-lab-error {
  border-top: rgb(182, 45, 45);
  border-bottom: rgb(182, 45, 45);
  border-left: rgb(182, 45, 45);
}

.lab-error {
  border-top: rgb(182, 45, 45);
  border-bottom: rgb(182, 45, 45);
}

.last-lab-error {
  border-top: rgb(182, 45, 45);
  border-bottom: rgb(182, 45, 45);
  border-right: rgb(182, 45, 45);
}