.login-page {
  background-color: #64b647;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.scout-logo {
  width: 100%;
  max-width: 450px;
  margin-bottom: 20px;
}

.placeholder-icon {
  color: #84857a;
  margin-right: -52px;
  position: relative;
  padding: 18px;
}

.forgot-password {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  text-decoration: underline;
  color: white;
  font-weight: 700;
}

.login-btn {
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 7px 10px;
  width: 100%;
  cursor: pointer;
}

.extra-text {
  color: white;
  margin-top: 10px;
  font-size: 14px;
}

.error-div {
  background-color: #b44a4a;
  color: white;
  padding: 60px 0 4px 22px;
  border-radius: 7px;
  margin-top: -55px;
  margin-left: -2px;
  margin-right: -2px;
  font-weight: 700;
  font-size: small;
}

.email-pass-error {
  background-color: #b44a4a;
  color: white;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: -15px;
  border-radius: 5px;
  width: calc(100vw - 20px);
  max-width: 390px;
  display: flex;
  align-items: center;
}

.email-pass-success {
  display: flex;
  align-items: center;
  border: 2px solid white;
  color: white;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: -15px;
  border-radius: 5px;
  width: calc(100vw - 20px);
  max-width: 390px;
}

.mr-n-50 {
  margin-right: -50px;
}

input:focus {
  outline: none;
}

.auth-input-field::placeholder {
  font-style: italic;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}

.reset-password-text {
  color: white;
  font-weight: bold;
  font-size: 17px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.new-tab-link {
  color: white;
  text-decoration: underline;
}

.new-tab-link:hover {
  color: white;
}

#float-label-auth {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}

#float-label-auth input {
  width: 100%;
  height: 56px;
  padding: 5px 16px 0 40px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#float-label-auth label {
  font-style: italic;
  font-weight: bold;
  padding: 0 45px;
  color: #84857a;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  margin-top: -8px;
}

#float-label-auth:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

#float-label-auth .Active {
  transform: translate(0, 12px) scale(0.75);
}

.pass-eye {
  position: absolute;
  top: calc(50% - 7px);
  right: 12px;
  cursor: pointer;
  color: #84857a;
}
