.switch-routes {
  position: fixed;
  margin-left: 230px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.__react_component_tooltip {
  max-width: 300px !important;
  z-index: 5000 !important;
}
