.head-tab-btns {
  background: #fff;
  padding: 0 30px;
}
.head-tab-btns .btn {
  color: #84857a;
  font-size: 15px;
  font-weight: 500;
  border-radius: 2px;
  padding: 10px 15px;
  border: 0;
  border-bottom: 3px solid transparent;
  min-width: 192px;
}
.head-tab-btns .btn.active {
  background: #e8f9d2;
  font-weight: 700;
  border-color: #74b44a;
  color: #2f481e;
}

th {
  font-size: 14px !important;
  font-style: italic;
}
td strong {
  font-weight: 600;
}

.btn-danger {
  border-radius: 8px !important;
  margin-left: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.center-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.webkit-fill-available {
  width: -webkit-fill-available;
}
