.strikethrough {
  text-decoration: line-through;
}

.cost-custom-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ag-theme-alpine .ag-header {
  background-color: #e4fad3 !important;
  color: #000 !important;
  font-size: 14px;
  font-weight: bold;
}

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #c5c5c5 !important;
}

.ag-theme-alpine .ag-row {
  border-bottom: 1px solid #c5c5c5!important;
}

.ag-theme-alpine .ag-row-selected {
  background-color: transparent !important;
}

.ag-theme-alpine .ag-cell {
  border-right: 1px solid #c5c5c5 !important;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #1e293b; 
}

.ag-cell.ag-cell-inline-editing {
  border: 2px solid #699e46 !important; /* Set custom border color */
  background-color: transparent !important;
}

.ag-cell.ag-cell-inline-editing {
  border: 2px solid #699e46 !important;
  box-shadow: 0 0 4px #699e46 !important;
}

.ag-cell-focus {
  outline: none !important;
  border: none !important;
}

.ag-row-hover {
  background-color: transparent !important;
}

.ag-cell.ag-cell-inline-editing {
  border: none !important;
  background-color: transparent !important;
}

.ag-header-cell:hover {
  background-color: transparent !important;
}


.ag-theme-alpine .ag-checkbox-input-wrapper input:checked + .ag-checkbox-input {
  background-color: #699e46 !important; /* Green check background */
  border-color: #699e46 !important; /* Green border */
}
.ag-theme-alpine .ag-checkbox-input {
  width: 20px !important;
  height: 20px !important;
}

/* Increase checkbox wrapper size */
.ag-theme-alpine .ag-checkbox-input-wrapper {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgba(105, 158, 70, 0.2) !important; /* Light green highlight */
}



.ag-theme-alpine .ag-checkbox-input-wrapper:focus {
  outline: 2px solid #699e46 !important; 
}

.ag-theme-alpine, .ag-theme-alpine-dark {
  --ag-alpine-active-color: #699e46 !important;
  --ag-selected-row-background-color: #e4fad370 !important;
  --ag-row-hover-color: #e4fad370 !important;
  --ag-column-hover-color: #e4fad381 !important;
  --ag-input-focus-border-color: #e4fad360 !important;
}


.ag-header-cell .ag-header-cell-menu-button {
  opacity: 1 !important; /* Make filter icon always visible */
  visibility: visible !important;
}

.ag-header-cell .ag-icon-filter {
  opacity: 1 !important; /* Make filter icon always visible */
  visibility: visible !important;
}


.checkInputCnt {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.checkInputCnt:hover {
  cursor: pointer;
}


.select_checkbox {
  position: relative;
  appearance: none; 
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-color: white; 
  border: 1px solid #74b44a; 
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  margin-top: 2px;
}

.hidden_select_checkbox {
  border: 1px solid lightgray; 
  position: relative;
  appearance: none; 
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  margin-top: 2px;
}

.select_checkbox:checked {
  background-color: #74b44a;
  border-color: #74b44a;   }

.select_checkbox:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.ag-icon-menu {
  margin-right: 4px;
  margin-top: 4px;
}
.ag-icon-menu::before {
  content: var(--ag-icon-font-code-filter, "\f115") !important;
  font-size: 20px;
  font-weight: 700;
  color: #699e46;
}


.ag-header-cell-filtered .ag-icon-menu::before {
  color: white !important;
  background-color: #74b44a;
  border-radius: 2px;
  padding: 3px 5px 3px 2px;
}


.ag-icon-filter::before {
  content: "" !important;
}

.ag-icon-grip::before {
  content: var(--ag-icon-font-code-menu, "\f11e") !important;
  font-size: 20px;
  font-weight: 900;
  color: gray !important;
}


/* .ag-root {
  user-select: text !important; /* Allows text selection 
} 
/* .ag-cell {
  user-select: text !important; /* Enables selection for individual cells 
} */