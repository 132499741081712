.button-container {
  display: flex;
  position: relative;
  width: 200px;
  height: 45px;
  margin: 10px 0;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  overflow: hidden;
}

.button {
  flex: 1;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  color: #699e46;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  z-index: 0;
}

.button:focus {
  border: none;
  outline: none;
  }

.button.active {
  color: white;
  transition: color 0.3s ease-in-out;
}

.tab-icon {
  margin-right: 5px;
}

.divider {
  width: 2px;
  background: gray;
  height: 70%;
  align-self: center;
}


/* Sliding Background */
.background {
  position: absolute;
  width: 50%;
  height: 100%;
  background: #699e46;
  border-radius: 8px;
  transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0; /* Initially hidden */
}
