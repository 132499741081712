.email-sender-form .e-label {
  font-size: 16px;
  color: #2f481e;
  font-weight: 500;
}

/* .small-trumboyg .trumbowyg-box,
.small-trumboyg .trumbowyg-editor,
.small-trumboyg .trumbowyg-textarea {
  height: 150px;
} */

.small-trumboyg .trumbowyg-editor,
.small-trumboyg .trumbowyg-textarea {
  min-height: 300px;
}

.small-trumboyg .trumbowyg-box,
.small-trumboyg .trumbowyg-editor {
  min-height: 300px;
}


.emailChip:hover {
  cursor: pointer;
}