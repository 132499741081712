.setting-tab-btns {
  background: #fff;
  padding-left: 30px !important;
}
.setting-tab-btns .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: none;
  border-color: white !important;
}
.setting-tab-btns .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: white !important;
  border-left: 0px;
  border-bottom: 3px solid #74b44a !important;
  background: #e8f9d2 !important;
  font-weight: 600 !important;
}
.setting-tab-btns a:hover {
  color: #212529;
}

.setting-tab-btns a {
  color: #84857a;
  font-size: 15px;
  padding: 10px 15px;
  font-size: 16px;
}
.setting-tab-btns .nav-link {
  font-weight: normal;
  padding: 10px 40px;
}

.billing-table .table td p {
  margin: 0;
  font-size: 14px;
  line-height: 37px;
  font-weight: 600;
  text-decoration: none;
  color: #2f481e;
}
.billing-table .table thead th {
  font-size: 13px !important;
}

.heading-rem {
  font-size: 15px;
  color: #2f481e;
}

.remaining-bill {
  font-size: 27px;
  margin-right: 10px;

  color: #2f481e;
  font-weight: 600;
}

.bill-amount {
  display: flex;
  min-width: 200px;
}

.license-counter {
  color: #74b44a;
  font-size: 40px;
  display: flex;
}

.mt-less1 {
  margin-top: -7px;
}
.mt-less2 {
  margin-bottom: -7px;
}

.span-line {
  max-width: 20px;
  border: 1px solid #dee2df;
  display: block;
  margin-left: 3px;
}
.licences-details {
  border-right: 1px solid #e7ece8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.licenses-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-text {
  font-size: 24px;
  color: #2f481e;
  font-weight: bold;
}

.pd-mar {
  padding-left: 20%;
}

.modal-texting {
  margin-left: 30px;
  padding-right: 30px;
  margin-right: 25px;
}

.counter-btn i {
  cursor: pointer;
}

.border-div {
  border-bottom: 2px solid #dee2df;
}

.company-details textarea:focus {
  border-color: #dddddd;
}
.card-on-file .card-file-data {
  height: 55px;
  border-radius: 8px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
}
.card-on-file .card-number {
  color: #2f481e;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 8px;
  margin-left: 25px;
}
.card-image {
  margin-left: 20px;
}

.american-card-icon {
  height: 37px;
  width: 42px;
}

.bg-table-billing-shadow {
  box-shadow: 0 2px 52px -20px rgba(132, 133, 122, 0.5);
  border-radius: 8px;
}

.spinner-div {
  display: flex;
  height: inherit;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.align-text-coupon {
  display: flex;
  justify-content: center;
  /* border-top: 1px solid #dee2df; */
}

.border-line-modal {
  border-top: 1px solid #dee2df;
  height: 1px;
  width: 274px;
}


.user-checkbox {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    gap: 30px;
  
}

.user-checkbox label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  gap: 10px;
}


.user-checkbox input {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid #74b44a !important;
  background-color: #fff !important;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.user-checkbox input:checked {
  background-color: #74b44a !important;
  border: 1px solid #74b44a !important;
}

.user-checkbox input:checked::after {
  content: "✓";
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}